import 'magnific-popup';
import './bit';

jQuery( document ).ready(function($) {
    $('#dates').bit({ 
        'artist': 'Bleachers',
        limit: 0,
        show_btn: true,
        show_y: false,
        show_rsvp: false,
        tickets_msg : 'Buy Tickets',
        template    : $(`
            <div class="event">
              <div class="date">
                  <span class="dow"></span>
                  <span class="month"></span>
                  <span class="day"></span>
                  <span class="year"></span>
              </div>
              <div class="location">
                  <span class="city">,</span>
                  <span class="region">,</span>
                  <span class="country"></span>
              </div>
              <div class = "location2">
                  <span class="venue"></span>
              </div>
              <div class="tickets"></div>
            </div>
        `),
    });

    $('.apple-btn').magnificPopup({
        items: {
            src: '#apple-popup',
            type: 'inline'
        }
    });

    $('.newsletter').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();

        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
            },
            error: function (err) {
                console.log(err);
            }
        });
    });
    $('.scroll').on('click', function(e) {
      const TARGET = $(this).attr('href');

      e.preventDefault();
        $('.page-wrap').animate({
          scrollTop: $(TARGET).offset().top
        });
    });
    if (window.innerWidth > 1000){
      $( ".drag, #header .text-block, .logo-text" ).draggable({
        containment: ".page-wrap"
      });
    }

    $('body').on('click', function(e){
      $('.dropdown-wrap').removeClass('open');
    })

    

    $('.dropdown-wrap').on('click', function(e){
        e.stopPropagation();
    })

    $('.dropdown-toggle').on('click', function(e){
        e.preventDefault();

        $(this).closest('.dropdown-wrap').toggleClass('open');
    })

    let SMEPreSave = {
		state: null,
		spotify_user: null,
		url_vars: [],
		getURLVars: function (callback, form_id) {
		  let hash;
		  let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
		  for (let i = 0; i < hashes.length; i++) {
			hash = hashes[i].split('=');
			this.url_vars.push(hash[0]);
			this.url_vars[hash[0]] = hash[1];
			  }
		  this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
		  this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
		  this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
		  this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
		  this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
		  if (typeof callback === 'function') {
			callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
		  }
		}
	  };
	  
	  /* String state
		*  The status of the pre-save.
		*  Int ae_member_id
		*  The Appreciation Engine member ID. This can be passed to the forms processor.
		*  String spotify_user
		*  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
		*  String deezery_user
		*  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
		*/
	  SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {
		// Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
		if (state === 'success' || state === 'thank-you') {
			jQuery.magnificPopup.open({
				items: {
				  src: '#thank-you',
				  type: 'inline'
				}
			});
		}
	});
});

document.addEventListener('musickitloaded', function () {
    
    let AmkPlaylists = [];
    let AmkAlbums = ["1567285157"];
    // Create a new SMEAppleMusic object with your parameters
    let sm = new SMEAppleMusic({
      ae: {
        ae: '7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd',
        brand_id: '3443710',
        segment_id: '1157143',
        activities: '{"actions":{"presave":61838},"mailing_list_optins":{"a0S61000001YpvUEAS":62030}}'
      },
      am: {
        dev_token: '',
        save_mode: 'library',
        custom_playlist_name: '',
        resources: {
          playlists: AmkPlaylists,
          albums: AmkAlbums
        }
      },
      sf: {
        form: '114703',
        default_mailing_list: ''
      },
      smf: {
        campaign_id: '114703',
        campaign_key: '9f84c77548f412ac2bf37d9c7eff4886'
      }
    });
	
	document.getElementById('apple_music_form').addEventListener('submit', function (e) {
		e.preventDefault();
		// Grab the email address value
		const email = document.getElementById('apple_music_email_address').value;
		// Pass the email address and opt in status
		// This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
		sm.doActions(email, true).then(function (res) {
		// Complete - handle your logic here
			jQuery.magnificPopup.open({
				items: {
				src: '#thank-you',
				type: 'inline'
				}
			});
		}).catch(function (err) {
		// Error - handle your logic here
		});
	});
});